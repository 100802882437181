import {Link, useLoaderData, useParams} from 'react-router-dom';
import styled from 'styled-components';
import {ReactComponent as ChevronLeft} from '../../assets/icons/chevron-left.svg';
import {ReactComponent as Cross} from '../../assets/icons/cross.svg';
import {ReactComponent as SuccessIcon} from '../../assets/icons/success.svg';
import {Heading2} from '../../components/Heading/Heading';
import {Text} from '../../components/Text/Text';
import {getData} from '../../api/fetch';
import {AcceptedFormTargets} from '../../constants/formTargets';
import qs from 'qs';
import {Fragment, useEffect, useState} from "react";
import {getTranslation} from "../../components/Utils/Utils";

const StyledFormResult = styled.div`
    padding: ${({$failed}) => $failed ? "20px 40px 52px 40px" : "52px 40px"};

    @media screen and (max-width: 768px) {
        padding: ${({$failed}) => $failed ? "16px 16px 40px 16px" : "40px 16px"};
    }
`;

const GoBackLink = styled(Link)`
    color: var(--color-button-secondary-text);
    display: inline-flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    font-weight: var(--fw-bold);
    font-size: var(--fs-button);
    text-decoration: none;
    padding: 12px 0;
    
    &:hover{
        color: var(--color-button-secondary-hover);
        
        svg {
            path {
                stroke-opacity: 0.6;
            }
        }
    }

    @media screen and (max-width: 768px) {
        margin-bottom: 10px;
    }
`;

const StyledChevronLeft = styled(ChevronLeft)`
    flex-shrink: 0;
    width: 24px;
    height: 24px;
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;

    @media screen and (max-width: 768px) {
        margin-bottom: 30px;
    }
`;

const Icon = styled.div`
    padding: 12px;
    border-radius: 50%;
    background-color: ${({$failed}) => $failed ? "var(--color-status-failed)" : "var(--color-status-success)"};
`;

const StyledCross = styled(Cross)`
    width: 56px;
    height: 56px;
`;

const StyledSuccessIcon = styled(SuccessIcon)`
    width: 56px;
    height: 56px;
`;

export async function formResultLoader({request, params}) {
    const {formTarget} = params;

    if (!formTarget || !(formTarget in AcceptedFormTargets)) {
        throw Object.assign(new Error("Not Found"), {status: 404, key: "NotFound"});
    }

    const url = new URL(request.url);
    const searchParams = new URLSearchParams(url?.search);
    const queryString = qs.stringify({
        hostedCheckoutId: searchParams.get("hostedCheckoutId"),
    });

    // TODO error afhandeling
    return await getData(`form/${AcceptedFormTargets[formTarget]}/payment/status${queryString ? `?${queryString}` : ''}`, { handleErrorLocally: true });
}

const FormResult = () => {
    const data = useLoaderData();

    if (data?.status === "CANCELLED_BY_CONSUMER") {
        return <PaymentFailed />
    }

    return <PaymentSuccess />
}

const PaymentFailed = () => {
    const params = useParams();

    const {formTarget} = params;

    if (!formTarget || !(formTarget in AcceptedFormTargets)) {
        throw Object.assign(new Error("Not Found"), { status: 404, key: "NotFound"});
    }

    const [translation, setTranslation] = useState({
        "title": "",
        "description": "",
        "footer": "",
        "back": ""
    });

    const config = {
        "introweken-owl-cms": "info@owl.leidenuniv.nl",
        "introweken-hop-cms": "info@hop.leidenuniv.nl",
        "introweken-elcid-cms": "info@elcid.leidenuniv.nl"
    }
    const email = config[process.env.REACT_APP_TARGET_CMS] || null;

    useEffect(() => {
        const fetchTranslation = async () => {
            let general = await getTranslation(formTarget === "generic"  ? "GenericPaymentFailed" : "PaymentFailed")
            let footer = await getTranslation("Footer")
            let back = await getTranslation("BackToForm")

            setTranslation({
                "title": general.title,
                "description": general.description,
                "footer": footer.description,
                "back": back.title
            });
        };

        fetchTranslation();
    }, []);

    return (
        <StyledFormResult $failed>
            {/* TODO eventueel nog studentId / mentorId hier indien bekend? */}
            <GoBackLink to={`/${formTarget}`}><StyledChevronLeft /> {translation.back}</GoBackLink>

            <IconWrapper>
                <Icon $failed>
                    <StyledCross />
                </Icon>
            </IconWrapper>

            <Heading2 $align="center">{translation.title}</Heading2>
            <Text $align="center">{translation.description.split('\n').map((line, index) => (
                <Fragment key={index}>
                    {line}
                    <br />
                </Fragment>
            ))}</Text>
            <Text $align="center">{translation.footer} {email && <Link to={`mailto:${email}`}>{email}</Link>}</Text>
        </StyledFormResult>
    );
}

const PaymentSuccess = () => {
    const [translation, setTranslation] = useState({
        "title": "",
        "description": "",
        "footer": ""
    });

    const config = {
        "introweken-owl-cms": "info@owl.leidenuniv.nl",
        "introweken-hop-cms": "info@hop.leidenuniv.nl",
        "introweken-elcid-cms": "info@elcid.leidenuniv.nl"
    }
    const email = config[process.env.REACT_APP_TARGET_CMS] || null;

    useEffect(() => {
        const fetchTranslation = async () => {
            let general = await getTranslation("Success")
            let footer = await getTranslation("Footer")

            setTranslation({
                "title": general.title,
                "description": general.description,
                "footer": footer.description + "\n"
            });
        };

        fetchTranslation();
    }, []);

    return (
        <StyledFormResult>
            <IconWrapper>
                <Icon>
                    <StyledSuccessIcon />
                </Icon>
            </IconWrapper>

            <Heading2 $align="center">{translation.title}</Heading2>
            <Text $align="center">{translation.description.split('\n').map((line, index) => (
                <>
                    {line}
                    <br />
                </>
            ))}</Text>
            <Text $align="center">{translation.footer} {email && <Link to={`mailto:${email}`}>{email}</Link>}</Text>

        </StyledFormResult>
    );
}

export default FormResult;