
import {getData} from "../../api/fetch";
import styled from "styled-components";
import phone_background from '../../assets/partner/phone_background.png';
import phone_overlay from '../../assets/partner/phone_overlay.png';
import {useLoaderData} from "react-router-dom";
import {HTMLText} from "../../components/Text/Text";


const StyledPartnerRoute = styled.div`
    margin: auto;
`;

const PhoneFrame = styled.div`
    display: flex;
    background-image: url(${phone_background});
    width: 375px;
    height: 844px;
`;

const ScreenFrame = styled.div`
    margin: auto;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 350px;
    height: 819px;
    border-radius: 40px;
    overflow-y: auto;
    overflow-x: hidden;


    /* Hide scrollbar track */
    &::-webkit-scrollbar {
        background-color: transparent;
        width: 15px;
    }

    /* Scrollbar thumb */
    &::-webkit-scrollbar-thumb {
        border-radius: 20px;
        border: 4px solid transparent;
        background-color: rgba(0,0,0,0.3);
        background-clip: content-box;
    }

    /* Scrollbar track (background) */
    &::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Optional: Prevent content shifting when scrollbar appears */
`;

const PhoneOverlay = styled.img`
    width: 350px;
    position: absolute;
    z-index: 2;
`;

const GradientBanner = styled.div`
    position: absolute;
    width: 350px;
    height: 110px;
    border-radius: 40px 40px 0 0;
    background: rgb(238,238,238);
    background: linear-gradient(0deg, rgba(238,238,238,0) 0%, rgba(72,72,72,1) 100%);
`;

const ItemImage = styled.img`
    width: 350px;
    height: 260px;
    border-radius: 40px 40px 0 0;
`;

const ContentContainer = styled.div`
    margin-bottom: 50px;
`;

const TextContainer = styled.div`
    height: 100%;
    padding: 20px 17px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
`;

const ItemTitle = styled.h1`
    font-size: 21px;
    margin: 0;
`;

export async function partnerLoader({request, params}) {
    const {itemId} = params;

    if (!itemId) {
        throw Object.assign(new Error("Not Found"), {status: 404, key: "NotFound"});
    }

    return await getData(`partner/${itemId}`, { handleErrorLocally: true });
}

const Partner = () => {
    const data = useLoaderData();

    return (
        <StyledPartnerRoute>
            <PhoneFrame>
                <ScreenFrame>
                    <PhoneOverlay src={phone_overlay}/>
                    <GradientBanner />

                    <ContentContainer>
                        <ItemImage src={data.imageUrl}/>
                        <TextContainer>
                            <ItemTitle>{data.title}</ItemTitle>
                            <HTMLText dangerouslySetInnerHTML={{__html: data.description}} />
                        </TextContainer>
                    </ContentContainer>

                </ScreenFrame>
            </PhoneFrame>
        </StyledPartnerRoute>
    )
}

export default Partner