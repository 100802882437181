import {Link, useLoaderData, useOutletContext} from 'react-router-dom';
import styled from 'styled-components';
import {ReactComponent as SuccessIcon} from '../../assets/icons/success.svg';
import {Heading2} from '../../components/Heading/Heading';
import {Text} from '../../components/Text/Text';
import {getTranslation} from "../../components/Utils/Utils";

const StyledFormResult = styled.div`
    padding: ${({$failed}) => $failed ? "20px 40px 52px 40px" : "52px 40px"};

    @media screen and (max-width: 768px) {
        padding: ${({$failed}) => $failed ? "16px 16px 40px 16px" : "40px 16px"};
    }
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;

    @media screen and (max-width: 768px) {
        margin-bottom: 30px;
    }
`;

const Icon = styled.div`
    padding: 12px;
    border-radius: 50%;
    background-color: ${({$failed}) => $failed ? "var(--color-status-failed)" : "var(--color-status-success)"};
`;

const StyledSuccessIcon = styled(SuccessIcon)`
    width: 56px;
    height: 56px;
`;

export async function formSuccessLoader({request, params}) {
    async function getData() {
        let general = await getTranslation("FormSuccess")
        let closure = await getTranslation("FormSuccessClosure")

        return {
            "title": general.title,
            "description": general.description,
            "closure": closure.description
        };
    }

    return await getData();
}

const FormSuccess = () => {
    const {data} = useOutletContext();
    const { title, description, closure } = useLoaderData();

    const config = {
        "introweken-owl-cms": {
            "title": "Winter OWL",
        },
        "introweken-hop-cms": {
            "title": "HOP",
            "email": "info@hop.leidenuniv.nl",
        },
        "introweken-elcid-cms": {
            "title": "EL CID",
            "email": "vrijwilligers@elcid.leidenuniv.nl"
        }
    }
    const formResult = config[process.env.REACT_APP_TARGET_CMS] || null;

    return (
        <StyledFormResult>
            <IconWrapper>
                <Icon>
                    <StyledSuccessIcon />
                </Icon>
            </IconWrapper>

            <Heading2 $align="center">{title}</Heading2>
            <Text $align="center">{description.replace("{form}", formResult.title)}</Text>
            <Text $align="center" $noMargin>{closure} <Link to={`mailto:${data.contactEmail}`}>{data.contactEmail}</Link></Text>
        </StyledFormResult>
    );
}

export default FormSuccess;