import {createContext, useState, useContext, useEffect} from "react";

export const FormContext = createContext(null);

export default function FormProvider({children}) {
    const [formValues, setFormValues] = useState({});
    const [stepIndex, setStepIndex] = useState(0);

    const updateFormValues = (data, questionnaireUUID) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            [questionnaireUUID]: {
                ...prevValues[questionnaireUUID],
                ...data,
            }
        }));
    };

    const removeSpecificFormValues = (questionnaireUUID) => {
        setFormValues((prevValues) => {
            const updatedValues = { ...prevValues };
            if (updatedValues[questionnaireUUID]) {
                delete updatedValues[questionnaireUUID];
            }
            return updatedValues;
        });
    };

    useEffect(() => {
        const { pathname, search } = window.location;

        let updatedPathname = pathname;

        if (pathname.includes('/volunteer')) {
            updatedPathname = pathname.replace('/volunteer', '/crew');
        }

        const urlParams = new URLSearchParams(search);
        if (urlParams.has("mentorId")) {
            urlParams.set('guideId', urlParams.get("mentorId"));
            urlParams.delete('mentorId');
        }

        const updatedQueryString = urlParams.toString();

        const updatedUrl = `${updatedPathname}${updatedQueryString ? `?${updatedQueryString}` : ''}`;

        if (updatedPathname !== pathname || updatedQueryString !== search.slice(1)) {
            window.history.replaceState(null, '', updatedUrl);
        }
    }, []);

    return (
        <FormContext.Provider value={{
            formValues,
            updateFormValues,
            removeSpecificFormValues,
            stepIndex,
            setStepIndex,
        }}>
            {children}
        </FormContext.Provider>
    );
}

export const useFormContext = () => useContext(FormContext);

